const CommercialRoutes = [
    {
        path: 'comm/',
        //component: () => import('@/components/sbx/commercial/comm_start.vue')
        component: () => import('@/components/sbx/commercial/analysis/AnalysisCommercialView.vue')
    },
    {
        path: 'comm/newpo/:type/:id',
        name: 'commercial-programmed-order',
        component: () => import('@/components/sbx/commercial/programmed_orders/ProgrammedOrdersView.vue')
    },
    {
        path: 'comm/drumpo',
        name: 'commercial-drum-programmed-order',
        component: () => import('@/components/sbx/commercial/drum_programmed_orders/DrumProgrammedOrdersView.vue')
    },
    {
        path: 'comm/newrq/:type/:id',
        name: 'commercial-orders-rq',
        component: () => import('@/components/sbx/commercial/createorders.vue')
    },
    {
        path: 'comm/cot/list',
        component: () => import('@/components/sbx/trade/quotlist.vue')
    },
    {
        path: 'comm/cot/:type/:id',
        name: 'commercial-cot-rq',
        component: () => import('@/components/sbx/commercial/createcot.vue')
    },
    {
        path: 'comm/cart',
        component: () => import('@/components/sbx/trade/cartera.vue')
    },
    {
        path: 'comm/rel',
        component: () => import('@/components/sbx/trade/pendingreleased.vue')
    },
    {
        path: 'comm/appr',
        component: () => import('@/components/sbx/trade/pendingapproved.vue')
    },
    {
        path: '/comm/drump',
        component: () => import("@/components/sbx/commercial/drum_commercial/CommercialDrumViewV2.vue")
        // component: () => import('@/components/sbx/trade/commercial_orders.vue')
    },
    {
        path: '/comm/prep',
        component: () => import('@/components/sbx/delivery/prepare.vue')
    },
    {
        path: '/comm/conf',
        component: () => import('@/components/sbx/delivery/check_deliv.vue')
    },
    {
        path: '/comm/inv',
        component: () => import('@/components/sbx/delivery/deliveries.vue')
    },
    /// CONFIGURACIÓN COMERCIAL
    {
        path: '/commercial/forescast',
        component: () => import('@/components/sbx/commercial/forescast')
    }
]

export {
    CommercialRoutes
}