/**
 * @file I18n configuration file
 * @copyright Daniel Salazar 2023
 * @mail <dsalazar@smgsoftware.com>
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
import enMessages from '../../../locales/en.json'
import esMessages from '../../../locales/es.json'
import mantEs from '@/locales/maintenance/mantEs.json'
import mantEn from '@/locales/maintenance/mantEn.json'
import iotEn from '@/locales/iot/iotEn.json'
import iotEs from '@/locales/iot/iotEs.json'

import plannEs from '@/locales/planning/plannEs.json'
import plannEn from '@/locales/planning/plannEn.json'

import prchEn from '@/locales/purchases/prchEn.json'
import prchEs from '@/locales/purchases/prchEs.json'

import commEn from '@/locales/commercial/commEn.json'
import commEs from '@/locales/commercial/commEs.json'

import rtEn from '@/locales/realtime/rtEn.json'
import rtEs from '@/locales/realtime/rtEs.json'

Vue.use(VueI18n)

const messages = {
    en: {
        ...enMessages,
        ...mantEn,
        ...plannEn,
        ...prchEn,
        ...iotEn,
        ...commEn,
        ...rtEn,
    },
    es: {
        ...esMessages,
        ...mantEs,
        ...plannEs,
        ...prchEs,
        ...iotEs,
        ...commEs,
        ...rtEs,
    },
}


export const i18n = new VueI18n({
    locale: localStorage.getItem("selectedLanguage") || "es",
    messages,
})



