import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'

import { RealtimeRoutes } from "./realtime.routes"
import { ReportRoutes } from "./reports.routes"
import { ProductionRoutes } from "./production.routes"
import { PlanningRoutes } from "./planning.routes"
import { CommercialRoutes } from "./commercial.routes"
import { MaintenanceRoutes } from './maintenance.routes'
import { InventRoutes } from './invent.routes'
import { ConfigurationRoutes } from './configuration.routes'
import { CostsRouters } from './costs.routers'
import { UsersRoutes } from './users.routes'
import { SysconfigRoutes } from './sysconfig.routes'
import { DashboardRoutes } from './dashboard.routes'
import { PurchasesRoutes } from './purchases.routes'

// Layouts
import Layout1 from '@/layout/Layout2'
import { cl } from '@fullcalendar/core/internal-common'
import { BitacoraRoutes } from './bitacora.routes'
import { QualityRoutes } from './quality.routes'
import { IOTRoutes } from './iot.router'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/login',
      // component: () => import('@/components/sbx/Login')
      component: () => import('@/components/sbx/LoginV2.vue')
    },

    {
      path: '/print/:id',
      component: () => import('@/components/sbx/trade/invoicePrint.vue')
    }, 

    {
    path: '/',
    component: Layout1,
    children: [{
      path: '',
      component: () => import('@/components/home')
      // component: () => import('@/components/sbx/globals/realtime_view_v2')
    }, 

    {
      path: 'dash/gerencial',
      component: () => import('@/components/sbx/dashboard/gerencial')
    }, 
    {
      path: 'dash/comm',
      component: () => import('@/components/sbx/dashboard/commercial')
    }, 
    {
      path: 'dash/cap',
      component: () => import('@/components/sbx/dashboard/planning')
    }, 

    ...RealtimeRoutes,

    ...ReportRoutes,

    ...ProductionRoutes,

    ...PlanningRoutes,

    ...CommercialRoutes,

    ...MaintenanceRoutes,

    ...InventRoutes,

    ...ConfigurationRoutes,

    ...CostsRouters,

    ...UsersRoutes,

    ...SysconfigRoutes,

    ...BitacoraRoutes,

    ...QualityRoutes,

    ...DashboardRoutes,

    ...PurchasesRoutes,

    ...IOTRoutes
    ]
  }]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
