<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
  >
    <!-- Brand demo (see demo.css) -->
    <b-navbar-nav
      class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto"
      v-if="sidenavToggle"
    >
      <a
        class="nav-item nav-link px-0 mr-lg-4"
        href="javascript:void(0)"
        @click="toggleSidenav"
      >
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-brand
      @click="modules = ''"
      to="/"
      class="app-brand demo py-0 mr-4"
    >
      <!-- <span class="app-brand-logo"> -->

      <img
        :src="require('@/components/i40/imgs/logosfinal-01.png')"
        class="img-responsive mt-0 mb-0"
        width="130"
        alt="Responsive image"
      />
      <!-- </span> -->
      <!-- <span class="app-brand-text demo font-weight-normal ml-2">Appwork</span> -->
    </b-navbar-brand>

    <!-- Sidenav toggle (see demo.css) -->

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <!-- <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:200px">
          </span>
          v-if="isOptionValid('/rt') 
        </label> -->
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'rt'"
      >
        <b-dd-item class="ml-0" to="/rt" :exact="true">{{
          $t("realTimeModuleLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'prd'"
      >
        <b-dd-item class="ml-0" to="/prd" :exact="true">{{
          $t("productionLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'ppl'"
      >
        <b-dd-item class="ml-0" to="/ppl" :exact="true">{{
          $t("textPlanning")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'mnt'"
      >
        <b-dd-item class="ml-0" to="/mnt" :exact="true">{{
          $t("maintenanceModuleLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'comm' || modules == 'cfgn'"
      >
        <b-dd-item class="ml-0" to="/comm" :exact="true">{{
          $t("commercialModuleLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'invent'"
      >
        <b-dd-item class="ml-0" to="/invent" :exact="true">{{
          $t("inventoryModuleLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'cst'"
      >
        <b-dd-item class="ml-0" to="/cst" :exact="true">{{
          $t("costsModuleLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'sys'"
      >
        <b-dd-item class="ml-0" to="/sys" :exact="true">{{
          $t("informationSyncBoxModuleLabel")
        }}</b-dd-item>
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'sys'"
      >
        <b-dd-item
          v-if="check_access('admin_services', 'r')"
          to="/sys/task_scheduler"
          :exact="true"
          >{{ $t("scheduledTasksLabel") }}</b-dd-item
        >
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'sys'"
      >
        <b-dd-item
          v-if="check_access('info_check', 'r')"
          to="/sys/devices"
          :exact="true"
          >{{ $t("diagnosisLabel") }}</b-dd-item
        >
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'sys'"
      >
        <b-dd-item
          v-if="check_access('info_licence', 'r')"
          to="/sys/infolicense"
          :exact="true"
          >{{ $t("licenseLabel") }}</b-dd-item
        >
      </b-navbar-nav>

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'iot'"
      >
        <b-dd-item class="ml-0" to="/IoT" :exact="true">IoT</b-dd-item>
      </b-navbar-nav>

      <!-- Configuracion SyncBox -->
      <!-- <b-navbar-nav icon="fas fa-info-circle" v-if="isOptionValid('/sys')">
        <b-nav-item-dropdown icon="ion ion-ios-albums">
        <template slot="button-content">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Informacion SyncBox</span>
          </span>
        </template>
<b-dd-item v-if="check_access('admin_services', 'r')" to="/sys/task_scheduler" :exact="true">Tareas
  Programadas</b-dd-item>
<b-dd-item v-if="check_access('info_check', 'r')" to="/sys/devices" :exact="true">Diagnóstico</b-dd-item>
<b-dd-item v-if="check_access('info_licence', 'r')" to="/sys/infolicense" :exact="true">Licencia</b-dd-item>
</b-nav-item-dropdown>
</b-navbar-nav> -->

      <b-navbar-nav
        to="/rt"
        class="align-items-lg-center"
        v-if="modules == 'prch'"
      >
        <b-dd-item
          v-if="check_access('info_licence', 'r')"
          to="/prch"
          :exact="true"
          >{{ $t("titlePurchasesModule") }}</b-dd-item
        >
      </b-navbar-nav>

      <StartPurchasesOptions v-if="modules == 'prch'" />
      <AnalisysOptions v-if="modules == 'prch'" />

      <!-- HERRAMIENTAS -->
      <RealTimeToolsOptions v-if="modules == 'rt'" />
      <!-- HERRAMIENTAS -->

      <!-- BITACORA -->
      <RealTimeLogbookOptions v-if="modules == 'rt'" />
      <!-- BITACORA -->

      <!-- MODIFICACION DE TIEMPOS -->
      <RealTimeTimeOptions
        v-if="modules == 'rt'"
        :check_access="check_access"
      />
      <!-- MODIFICACION DE TIEMPOS -->

      <!-- INFORMES -->
      <RealTimeReportsOptions v-if="modules == 'rt'" />
      <!-- INFORMES -->

      <!-- CONTROL DE PISO -->
      <RealTimeFloorControlOptions
        v-if="modules == 'rt'"
        :check_access="check_access"
      />
      <!-- CONTROL DE PISO -->

      <!-- OPERACIONES -->
      <RealTimeOperationsOptions
        v-if="modules == 'rt' || modules == 'ppl'"
        :check_access="check_access"
      />
      <!--  OPERACIONS-->

      <!-- TAMBORES -->
      <PlanningDrumsOptions
        v-if="modules == 'prd' || modules == 'ppl'"
        :check_access="check_access"
      />
      <!-- TAMBORES -->

      <!-- PLANNING CONFIG -->
      <PlanningConfigOptions
        v-if="modules == 'ppl'"
        :check_access="check_access"
      />
      <!-- PLANNING CONFIG -->

      <!-- START QUOTATIONS -->
      <CommercialQuotationsOptions
        v-if="modules == 'comm' || modules == 'cfgn'"
        :check_access="check_access"
      />
      <!-- END QUOTATIONS -->

      <!-- START ORDERS -->
      <CommercialOrdersOptions
        v-if="modules == 'comm' || modules == 'cfgn'"
        :check_access="check_access"
      />
      <!-- END ORDERS -->

      <!-- START PROGRAMMED ORDERS -->
      <CommercialProgrammedOrdersOptions
        v-if="modules == 'comm' || modules == 'cfgn'"
        :check_access="check_access"
      />
      <!-- END PROGRAMMED ORDERS -->

      <!-- START DELIVERIES -->
      <CommercialDeliveriesOptions
        v-if="modules == 'comm' || modules == 'cfgn'"
      />
      <!-- END DELIVERIES -->

      <!-- INVENTORY DETAILS -->
      <InventoryDetailsOptions v-if="modules == 'invent'" />
      <!-- INVENTORY DETAILS -->

      <!-- COSTS ANALYSIS -->
      <CostsAnalysisOptions v-if="modules == 'cst'" />
      <!-- COST ANALYSIS -->

      <!-- Configuracion Operaciones IoT -->
      <ConfigIoT v-if="modules == 'iot'"></ConfigIoT>
      <SysconfigIoT v-if="modules == 'iot'"></SysconfigIoT>
      <!-- Configuracion Operaciones IoT -->

      <!-- COSTS REPORTS -->
      <CostsReportsOptions v-if="modules == 'cst'" />
      <!-- COSTS REPORTS -->

      <!-- Mantenimiento -->
      <MaintenanceOptions v-if="modules == 'mnt'" />
      <!-- Mantenimiento -->

      <!-- Solicitudes -->
      <MaintenanceRequestsOptions v-if="modules == 'mnt'" />
      <!-- Solicitudes -->

      <!-- Opciones de gestión -->
      <MaintenanceManagementOptions v-if="modules == 'mnt'" />
      <!-- Opciones de gestión -->

      <OptionsIoT v-if="modules == 'iot'"></OptionsIoT>
      <DashboardsOptions v-if="modules == 'iot'" />

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <!-- Configuracion de Control de Piso -->
        <!-- Configuracion de Control de Piso -->

        <!-- Configuracion de Control de Piso -->

        <!-- Configuracion de Control de Piso -->

        <!-- Configuracion Operaciones -->

        <!-- Configuracion Operaciones -->

        <!-- Configuracion General -->
        <SysconfigGeneralOptions
          v-if="modules == 'sys'"
          :check_access="check_access"
        />
        <!-- Configuracion General -->

        <!-- Configuracion Costos -->
        <CostConfigOptions
          v-if="modules == 'cst'"
          :check_access="check_access"
        />
        <!-- Configuracion Costos -->

        <!-- Configuracion Productos -->
        <b-navbar-nav
          class="align-items-lg-center"
          v-if="
            modules == 'rt' ||
            modules == 'prd' ||
            modules == 'ppl' ||
            modules == 'comm' ||
            modules == 'cst' ||
            modules == 'invent'
          "
        >
          <b-nav-item-dropdown icon="ion ion-ios-albums">
            <template slot="button-content">
              <span
                class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
              >
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
                  $t("textProducts")
                }}</span>
              </span>
            </template>
            <b-dd-item
              v-if="
                check_access('products_fammily', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              to="/cfpr/families/prd"
              :exact="true"
              >{{ $t("productFamilyLabel") }}</b-dd-item
            >
            <b-dd-item
              v-if="
                check_access('products_fammily', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              to="/cfpr/lines/prd"
              :exact="true"
              >{{ $t("productLinesLabel") }}</b-dd-item
            >
            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              to="/cfpr/master/checkcodes"
              :exact="true"
              >Check Items</b-dd-item
            >
            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              to="/cfpr/product/prd"
              :exact="true"
              >{{ $t("productsLabel") }}</b-dd-item
            >

            <b-dd-item
              to="/cfwh/warehouse"
              v-if="modules == 'invent'"
              :exact="true"
              >{{ $t("warehousesLabel") }}</b-dd-item
            >
            <b-dd-item
              to="/cfwh/ubc"
              v-if="modules == 'invent'"
              :exact="true"
              >{{ $t("locationsLabel") }}</b-dd-item
            >

            <!-- <sidenav-menu icon="fas fa-download">
              <template slot="link-text">Descargar</template> -->

            <b-dd-divider></b-dd-divider>
            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              @click.prevent="exportExcel('prd')"
              :exact="true"
              >Exportar Productos (Configuracion Basica)</b-dd-item
            >
            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              @click.prevent="exportExcel('rshort')"
              :exact="true"
              >Exportar Rutas (Configuracion Basica)</b-dd-item
            >
            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              @click.prevent="exportExcel('rcomplete')"
              :exact="true"
              >Exportar Rutas (Configuracion Completa)</b-dd-item
            >
            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              @click.prevent="exportExcel('bom')"
              :exact="true"
              >Exportar BOM</b-dd-item
            >

            <b-dd-item
              v-if="
                check_access('products_products', 'r') &&
                (modules == 'rt' ||
                  modules == 'prd' ||
                  modules == 'ppl' ||
                  modules == 'comm' ||
                  modules == 'cst' ||
                  modules == 'invent')
              "
              to="/cfpr/import/rshort"
              :exact="true"
              >Subir Rutas (Configuracion Basica)</b-dd-item
            >
            <!-- </sidenav-menu> -->
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- Configuracion Productos -->

        <!-- Configuracion Planeacion -->
        <PlanningCalendarOptions
          v-if="modules == 'ppl'"
          :check_access="check_access"
        />
        <!-- Configuracion Planeacion -->

        <!-- Configuracion Matenimiento -->
        <MaintenanceConfigOptions
          v-if="
            isOptionValid('/cfmnt') ||
            isOptionValid('/mnt') ||
            isOptionValid('/cfpr')
          "
        />
        <!-- Configuracion Matenimiento -->

        <!-- Configuracion Comercial -->
        <CommercialConfigOptions
          v-if="modules == 'comm'"
          :check_access="check_access"
        />
        <!-- Configuracion Comercial -->

        <!-- Configuracion Usuarios -->
        <SysconfigUsersOptions
          v-if="modules == 'sys'"
          :check_access="check_access"
        />
        <!-- Configuracion Usuarios -->

        <div
          class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
        >
          |
        </div>

        <b-nav-item-dropdown no-caret class="demo-navbar-notifications mr-lg-3">
          <template slot="button-content">
            <i
              class="ion ion-md-notifications-outline navbar-icon align-middle"
            ></i>
            <span
              class="badge badge-primary badge-dot indicator"
              v-if="listContent.length > 0"
            ></span>
            <span class="d-lg-none align-middle">&nbsp; Notifications</span>
          </template>

          <div class="bg-primary text-center text-white font-weight-bold p-3">
            {{ listContent.length }} Nueva Notificación
          </div>
          <b-btn
            class="mt-1"
            variant="outline-danger"
            block
            size="xs"
            @click="removeallalarm()"
            ><span class="ion ion-md-close"></span>&nbsp; Descartar Todas las
            Notificaciones</b-btn
          >
          <b-list-group flush v-for="(item, index) of listContent" :key="index">
            <b-list-group-item class="media d-flex align-items-center">
              <div
                class="ui-icon ui-icon-sm fas fa-clipboard-list bg-secondary border-0 text-white"
              ></div>
              <div class="media-body line-height-condenced ml-3">
                <!-- {{item}} -->
                <div class="text-dark">{{ item.title }}</div>
                <!-- <b-list-group-item  href="javascript:void(0)"> -->
                <div class="text-light small mt-1">
                  {{ item.message }}
                </div>
                <div class="text-light small mt-1">
                  {{ item.username }} {{ item.created }}

                  <b-btn
                    variant="outline-success borderless icon-btn"
                    class="btn-xs"
                    :to="{ path: '/btc/det/' + item.modules_id }"
                    target="_blank"
                    ><i class="fas fa-link"></i
                  ></b-btn>
                </div>
                <!-- </b-list-group-item> -->
                <!-- <div class="row bt-2">
                  <div class="col" style="padding-right:5px;">
                    <b-btn variant="success" block size="xs" @click="messageContent=true"><span class="ion ion-md-checkmark"></span>&nbsp; Leído</b-btn>
                  </div>
                  <div class="col"  style="padding-left: 5px;">
                    <b-btn variant="danger" block size="xs" @click="messageContent=true"><span class="ion ion-md-close"></span>&nbsp; Eliminar</b-btn>
                  </div>
                </div> -->
                <b-btn
                  variant="outline-danger"
                  block
                  size="xs"
                  @click="removealarm(item)"
                  ><span class="ion ion-md-close"></span>&nbsp;
                  {{ $t("deleteButtonLabel") }}</b-btn
                >
              </div>
            </b-list-group-item>
          </b-list-group>

          <!-- <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all notifications</a> -->
        </b-nav-item-dropdown>

        <!-- Divider -->
        <div
          class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
        >
          |
        </div>

        <b-nav-item-dropdown class="demo-navbar-user">
          <template slot="button-content">
            <span
              class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
            >
              <!-- <img :src="require('@/vendor/avatars/default.png')" alt class="d-block ui-w-30 rounded-circle"> -->
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{
                userInfo.name + " " + userInfo.lastname
              }}</span>
            </span>
          </template>

          <!-- <b-dd-item><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>
          <b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item>
          <b-dd-divider /> -->
          <b-dd-item @click="logout"
            ><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log
            Out</b-dd-item
          >
        </b-nav-item-dropdown>

        <div
          class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
        >
          |
        </div>

        <b-nav-item-dropdown class="demo-navbar-user">
          <template slot="button-content">
            <span
              class="d-inline-flex flex-lg-row-reverse align-items-center align-middle text-dark"
            >
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">
                <i class="fas fa-language"></i> {{ textButton }}</span
              >
            </span>
          </template>
          <b-dd-item @click="onChangeLanguage('es')">{{
            code_language === "es" ? "ESPAÑOL" : "SPANISH"
          }}</b-dd-item>
          <b-dd-item @click="onChangeLanguage('en')">{{
            code_language === "es" ? "INGLÉS" : "ENGLISH"
          }}</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- End right column -->
    </b-collapse>
  </b-navbar>
</template>

<script>
import { infouser } from "@/vendor/sbx/sbx-users/users";
import { infonotify } from "@/components/i40/js/notify";
import { infousers } from "@/components/i40/js/users";
import { infosysconfig } from "@/components/i40/js/sysconfig";
import { infomaster } from "@/components/i40/js/master";
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";

import OptionsIoT from "./modules/optionsIoT.vue";
import ConfigIoT from "./modules/configIoT.vue";
import SysconfigIoT from "./modules/sysconfigIoT.vue";
import MaintenanceOptions from "./modules/maintenance/MaintenanceOptions.vue";
import MaintenanceRequestsOptions from "./modules/maintenance/MaintenanceRequestsOptions.vue";
import MaintenanceManagementOptions from "./modules/maintenance/MaintenanceManagementOptions.vue";
import MaintenanceConfigOptions from "./modules/maintenance/MaintenanceConfigOptions.vue";
import RealTimeToolsOptions from "./modules/realtime/RealTimeToolsOptions.vue";
import RealTimeLogbookOptions from "./modules/realtime/RealTimeLogbookOptions.vue";
import RealTimeTimeOptions from "./modules/realtime/RealTimeTimeOptions.vue";
import RealTimeReportsOptions from "./modules/realtime/RealTimeReportsOptions.vue";
import RealTimeFloorControlOptions from "./modules/realtime/RealTimeFloorControlOptions.vue";
import RealTimeOperationsOptions from "./modules/realtime/RealTimeOperationsOptions.vue";
import PlanningDrumsOptions from "./modules/planning/PlanningDrumsOptions.vue";
import PlanningConfigOptions from "./modules/planning/PlanningConfigOptions.vue";
import PlanningCalendarOptions from "./modules/planning/PlanningCalendarOptions.vue";
import CommercialQuotationsOptions from "./modules/commercial/CommercialQuotationsOptions.vue";
import CommercialOrdersOptions from "./modules/commercial/CommercialOrdersOptions.vue";
import CommercialProgrammedOrdersOptions from "./modules/commercial/CommercialProgrammedOrdersOptions.vue";
import CommercialDeliveriesOptions from "./modules/commercial/CommercialDeliveriesOptions.vue";
import CommercialConfigOptions from "./modules/commercial/CommercialConfigOptions.vue";
import InventoryDetailsOptions from "./modules/inventory/InventoryDetailsOptions.vue";
import CostsAnalysisOptions from "./modules/costs/CostsAnalysisOptions.vue";
import CostConfigOptions from "./modules/costs/CostConfigOptions.vue";
import CostsReportsOptions from "./modules/costs/CostsReportsOptions.vue";
import SysconfigGeneralOptions from "./modules/sysconfig/SysconfigGeneralOptions.vue";
import SysconfigUsersOptions from "./modules/sysconfig/SysconfigUsersOptions.vue";
import StartPurchasesOptions from "./modules/purchases/StartPurchasesOptions.vue";
import AnalisysOptions from "@/layout/modules/purchases/AnalisysOptions.vue";
import DashboardsOptions from "./modules/DashboardsOptions.vue";

export default {
  name: "app-layout-navbar",

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,

    OptionsIoT,
    ConfigIoT,
    SysconfigIoT,
    DashboardsOptions,

    MaintenanceOptions,
    MaintenanceRequestsOptions,
    MaintenanceManagementOptions,
    MaintenanceConfigOptions,

    RealTimeToolsOptions,
    RealTimeLogbookOptions,
    RealTimeTimeOptions,
    RealTimeReportsOptions,
    RealTimeFloorControlOptions,
    RealTimeOperationsOptions,

    PlanningDrumsOptions,
    PlanningConfigOptions,
    PlanningCalendarOptions,

    CommercialQuotationsOptions,
    CommercialOrdersOptions,
    CommercialProgrammedOrdersOptions,
    CommercialDeliveriesOptions,
    CommercialConfigOptions,

    InventoryDetailsOptions,

    CostsAnalysisOptions,
    CostConfigOptions,
    CostsReportsOptions,

    SysconfigGeneralOptions,
    SysconfigUsersOptions,

    StartPurchasesOptions,
    AnalisysOptions,
  },

  data() {
    return {
      userInfo: {},
      listContent: [],
      binnacle: [],
      modules: "",

      code_language: null,
      textButton: "Idioma",
    };
  },

  methods: {
    async exportExcel(param) {
      let datainfo = [];
      await infomaster.getcontentdownload(param).then((data) => {
        datainfo = data.data;
      });

      let data = XLSX.utils.json_to_sheet(datainfo);
      const workbook = XLSX.utils.book_new();
      const filename = param;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onChangeLanguage(code) {
      this.$i18n.locale = code;
      this.code_language = code;
      this.textButton = this.code_language === "es" ? "Idioma" : "Language";

      localStorage.setItem("selectedLanguage", code);
    },

    getLanguageFromLocaleStorage() {
      const language = localStorage.getItem("selectedLanguage");

      if (language) {
        if (language === "en") {
          this.textButton = "Language";
        } else {
          this.textButton = "Idioma";
        }
      }
    },

    getnotify() {
      infonotify.notifycontroller("get", "get").then((data) => {
        this.listContent = [];
        if (data.data != "") {
          //
          this.listContent = data.data;
        }
      });
    },
    removeallalarm() {
      infonotify.notifycontroller([], "delete_all").then((data) => {
        this.getnotify();
      });
    },
    removealarm(item) {
      infonotify
        .notifycontroller(item.notifications_id, "delete")
        .then((data) => {
          this.getnotify();
        });
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    logout: function () {
      localStorage.removeItem("syncbox.cloud");
      this.$router.push(this.$route.query.redirect || "/login");
    },

    check_access(control, action) {
      return infousers.check_access(control, action);
      // return true
    },

    isOptionValid(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    validateURL() {
      this.modules = "";

      const routeToModule = {
        "/rt/": "rt",
        "/reprt/": "rt",
        "/prd/": "prd",
        "/ppl/": "ppl",
        "/cfws/": "ppl",
        "/mnt/": "mnt",
        "/comm/": "comm",
        "/invent/": "invent",
        "/cst/": "cst",
        "/sys/": "sys",
        "/IoT/": "iot",
        "/prch/": "prch",
        "///": "",
      };

      const previousRoute = sessionStorage.getItem("previousRoute");

      this.modules = routeToModule[previousRoute];
    },
  },
  watch: {
    "this.$route.path": function (val) {},
  },
  created() {
    this.getLanguageFromLocaleStorage();

    this.getnotify();

    this.validateURL();

    this.$options.interval = setInterval(this.getnotify, 20000);
  },
  beforeCreate() {
    let rs = infouser.currentUser();
    if (rs != "ERROR") {
      rs.then((response) => {
        this.userInfo = response.data;
      }).catch((e) => {});
    } else {
      this.$router.push(this.$route.query.redirect || "/login");
    }

    infosysconfig.functionalarea([], "select").then((data) => {
      this.binnacle = [];
      if (data.data != "") {
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];
          if (element.binnacle_active)
            this.binnacle.push({
              text: element.description,
              code: element.binnacle_code,
            });
        }
      }
    });
  },
};
</script>
